<template>
  <div class="row">
    <div class="col-12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <Confirm ref="confirm" :method="store"/>
      <transition name="slide">
        <div class="card border-warning">
          <div class="card-header">
            <strong class="text-primary">
              Cambiar contraseña del Docente
            </strong>
            <div class="card-header-actions">
              <button class="btn btn-outline-info"
                      title="Ver video de ayuda"
                      @click="$refs.modalVideo.mostrarVideo('video-alumno-nuevo.mp477777777777777777')">
                <font-awesome-icon icon="fas fa-question-circle"/>
                Ayuda
              </button>
            </div>
          </div>
          <div class="card-body">
            <form ref="frmNuevo" novalidate>
              <div class="row">
                <div class="col-3">
                  <label for="password">Nombre de usuario: </label>
                </div>
                <div class="col-6">
                  {{ nombreusuario }}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-3">
                  <label for="password">Nueva contraseña: </label>
                </div>
                <div class="col-4">
                  <input id="password" v-model="userpass.password" class="form-control" required="required"
                         type="password"/>
                  <b>8</b> caracteres, Mayúsculas, Minúsculas, Simbolos
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-3">
                  <label for="password_confirmation">Repetir nueva contraseña: </label>
                </div>
                <div class="col-4">
                  <input id="password_confirmation" v-model="userpass.password_confirmation" class="form-control"
                         required="required" type="password"/>
                </div>
              </div>
              <hr/>
              <button class="mr-2 btn btn-primary" type="button" @click="store()">Cambiar contraseña</button>
              <button class="btn btn-danger" type="button" @click="goBack">Regresar a la pantalla anterior</button>
            </form>
          </div>
          <div class="card-footer">
            <template v-if="nombreusuario!==''">
              <h5 class="text-primary">Designar el rol de jefe de carrera</h5>
              <table class="table table-bordered table-condensed table-sm">
                <thead>
                <tr>
                  <th></th>
                  <th>Facultad</th>
                  <th>Carrera</th>
                  <th class="text-center">
                    Jefe de carrera
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(carrera,index) in jefecarreralista">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td>{{ carrera.facultad }}</td>
                  <td>{{ carrera.carrera }}</td>
                  <td class="text-center">
                    <div class=" row">
                      <div class="col">No</div>
                      <div class="col">
                        <div class="custom-control custom-switch">
                          <input :id="'customSwitch'+carrera.id" v-model="carrera.jefe_carrera"
                                 :checked="carrera.jefe_carrera>0?'checked':''"
                                 class="custom-control-input" type="checkbox"
                                 @change="asignarRol(carrera.jefe_carrera, carrera.id)">
                          <label :for="'customSwitch'+carrera.id" class="custom-control-label">&nbsp;</label>
                        </div>
                      </div>
                      <div class="col">Si</div>
                    </div>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td colspan="4" class="text-center">
                    <small><em>Solo se listan las carreras registradas que tengan alumnos inscritos durante los últimos 3 años</em></small>
                  </td>
                </tr>
                </tfoot>
              </table>
            </template>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalVideo from "@/views/notifications/ModalVideo";
import Confirm from "@/views/notifications/Confirm";
import Table from "@/views/base/Table";

var modalPdf;

export default {
  name: 'EditUser',
  components: {Table, Confirm, ModalVideo, Alerts, Toast},
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  data: () => {
    return {
      userpass: {
        docente_id: 0,
        usuario_id: 0,
        password: '',
        password_confirmation: '',
      },
      nombreusuario: '',
      jefecarreralista: [],
    }
  },
  methods: {
    goBack() {
      this.$router.push({path: '/docente/'});
    },
    store() {
      if (!modalPdf.$refs.frmNuevo.checkValidity()) {
        modalPdf.$refs.frmNuevo.classList.add('was-validated');
      } else {
        modalPdf.$refs.alert.show('Cambiando contraseña');
        axios.post(this.$apiAdress + '/api/docente/password?token=' + localStorage.getItem("api_token"),
            modalPdf.userpass
        )
            .then(function (response) {
              modalPdf.$refs.mensajeToast.makeToast('', 'Contraseña cambiada satisfactoriamente.', 'success');
              modalPdf.$router.push({path: '/docente/'});
            })
            .catch(function (error) {
              modalPdf.$refs.alert.hide();
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            });
      }
    },
    asignarRol(asignar, carrera_id) {
      modalPdf.$refs.alert.show(asignar ? 'Asignar jefatura de carrera' : 'Eliminar jefatura de carrrera');
      axios.get(this.$apiAdress + '/api/docente/' + carrera_id + '/' + modalPdf.userpass.docente_id + '/' + localStorage.sedeSeleccionada + '/' + (asignar ? 'jefaturaasignar' : 'jefaturaeliminar') + '?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            modalPdf.$refs.mensajeToast.makeToast('', 'Datos cambiados satisfactoriamente.', 'success');
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          })
          .finally(function () {
            modalPdf.$refs.alert.hide();
          });
    }
  },
  mounted: function () {
    modalPdf = this;
    modalPdf.sedeSeleccionada = localStorage.sedeSeleccionada;
    modalPdf.userpass.docente_id = modalPdf.$route.params.id
    axios.get(this.$apiAdress + '/api/docente/' + localStorage.sedeSeleccionada + '/' + modalPdf.$route.params.id + '/docente?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          modalPdf.userpass.usuario_id = response.data.usuario_id;
          modalPdf.nombreusuario = response.data.nombreusuario;
          modalPdf.jefecarreralista = response.data.jefecarreralista;
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
  }
}

</script>
<style>